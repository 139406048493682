<template>
  <div class="mt-8">
    <div
      class="bg-white mx-auto p-4 md:rounded md:shadow max-w-4xl"
      v-if="model"
    >
      <sqr-input-text
        label="Object"
        :value="model.closeSubject"
        @change="fieldSet('closeSubject', $event)"
      />
      <sqr-input-textarea
        class="mt-4"
        label="Texte"
        :value="model.closeText"
        @change="fieldSet('closeText', $event)"
        :rows="25"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SqrInputText from '@/sqrd-ui/SqrInputText';
import SqrInputTextarea from '@/sqrd-ui/SqrInputTextarea';
export default {
  name: 'InquiryModelEmail',
  components: { SqrInputText, SqrInputTextarea },
  computed: {
    ...mapState('inquiryModel', { model: 'doc' }),
    ...mapState('inquiryModel', ['path'])
  },
  methods: {
    ...mapActions('updater', ['update']),
    fieldSet(field, value) {
      const path = this.path;
      return this.update({ path, doc: { [field]: value } });
    }
  },
  metaInfo() {
    const name = this.model?.name ?? '';
    const title = `${name} - e-mail - modèle de demande d'offres`;
    return { title };
  }
};
</script>
